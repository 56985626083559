.map-container{
    width: 100%;
  
}

.leaflet-div-icon{
    height: 30px !important;
    width: 30px !important;
    background-color: transparent !important;
    padding:0px !important;
    background-origin: padding-box;
    border: none !important;
}
.leaflet-div-icon-location{
    width: 100px !important;
    height: 100px !important;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

@keyframes flickerAnimation {
    0%   { opacity:0.6; }
    50%  { opacity:0; }
    100% { opacity:0.6; }
  }

.leaflet-div-icon-location .marker-pin {
    background-image: none !important;
    width: 100px !important;
    height: 100px !important;
    border-radius: 100px;
    background-color: #89CFF0;
    opacity: 0.6;
    animation: flickerAnimation 3s infinite;

}
.leaflet-div-icon-location .marker-circle{
    border:3px solid white;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    margin-left: -10px;
    margin-top: -10px;
    border-radius: 20px;
    background-color: #318CE7;
}

.marker-pin{
    height: 100%;
    width: 100%;
    background-color: transparent;
    color:white;
    text-align: center;
    position: relative;
    background-image: url("../../../public/location_pin_empty.svg");
    background-repeat: no-repeat;
    background-position: center;
    font-weight: bold;
}
.marker-pin span{
    position: absolute;
    left: 50%;
    width: 10px;
    height: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin:0px;
    padding:0px;
    top: 50%;
    font-size: 12px;
    margin-left: -5.8px;
    margin-top: -5.5px;
    background-color: transparent;
}

.marker-pin img{
    position: relative;
    width: 35px ;
    height: 35px;
    margin-top: 2px;
    margin-left: 2px;
}
.marker-pin:hover{
    background-image: url("../../../public/location_pin_empty_orange.svg");
    background-repeat: no-repeat;
    background-position: center;
}