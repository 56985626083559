.menu-container {
  height: 100%;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
}

.menu-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: relative;
}
.menu-button img {
  width: 18px;
  height: 18px;
  position: relative;
  left: 20px;
}
.menu-container .menu-box {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.menu-container .menu-box .menu-box-links {
  width: 100%;
  height: 110%;
  padding: 20px;
}

.menu-box-links-header {
  margin: 10px;
  padding: 0px;
}
.menu-box-links-header button {
  width: auto;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 100px;
  height: 100%;
  display: inline-block;
  text-align: start;
  padding: 0px;
  position: relative;
  top: 22px;
  text-align: start;
  left: -20px;
}

.menu-box-links-header h1 {
  font-family: "Roboto", sans-serif;
  width: 100%;
  text-align: start;
  font-size: 1.1em;
  font-weight: bold;
  text-align: center;
  margin: 0px;
}

.menu-box-links a {
  display: block;
  text-align: start;
  text-decoration: none;
  color: black;
  padding: 10px;
  padding-left: 0px;
}
.menu-box-links span {
  padding: 10px;
  display: block;
  padding-left: 0px;
}

.menu-box-deep-links {
  padding-left: 30px;
}

.close-icon {
  width: 35px;
  height: 35px;
}

.menu-box-search {
  border: 1px solid #acacac;
  text-align: start;
  overflow: hidden;
  margin-top: 20px;
  display: flex;
  align-items: center;
  height: 30px;
  padding: 10px;
}
.menu-box-search input {
  display: inline-block;
  width: 90%;
  outline: none;
  height: 30px;
  height: 100%;
  border: none;
  font-size: 20px;
}
.menu-box-search span {
  display: inline-block;
  width: 10%;
  height: 30px;
  text-align: end;
  border: none;
}

.menu-container-desktop {
  font-family: "Roboto", sans-serif;
  font-size: 1.1em;
  position: relative;
}
.menu-container-desktop > * {
  margin-left: 20px;
  background-color: transparent;
  border: none;
  text-decoration: none;
  color: black;
  font-size: 16px;
}
.menu-container-desktop img {
  width: 15px;
}

.menu-container-desktop .menu-dropdown {
  position: absolute;
  right: 0px;
  min-width: 300px;
  min-height: 400px;
  z-index: 10000000;
  background-color: white;
  box-shadow: 0px 0px 1px 1px #ccc;
  border-radius: 5px;
  padding: 10px;
}

.menu-container-desktop .menu-dropdown a {
  text-decoration: none;
  color: black;
  display: inline-block;
  width: 100%;
  padding: 5px;
}
