.description-details {
  font-family: "Roboto", sans-serif;
}

.description-container {
  padding: 25px 30px 15px 30px;
}
.description-container p {
  padding: 0px;
  margin: 0px;
}
.description-container h1 {
  text-align: start;
  color: #816638;
  font-weight: lighter;
  font-size: 40px;
}

.description-container img {
  width: 10px;
  margin-right: 10px;
}

.description-container span {
  font-size: 18px;
  font-weight: 500;
  color: #ccc;
  margin-top: 20px;
  display: block;
}

.description-container button {
  background-color: transparent;
  border: none;
  text-align: start;
  padding: 0px;
  margin: 10px;
  margin-left: 0px;
  cursor: pointer;
  color: #816638;
}
.description-container button:hover {
  color: black;
}

.header1 {
  font-family: "Lora", serif;
}
