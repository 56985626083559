.swiper-responsive {
  width: 100%;
  height: auto;
}
.swiper-responsive img {
  width: 100%;
  height: 100%;
}

.swipercaption {
  font-size: 0.75em;
  margin-top: 1px;
}

.swiper-pagination-bullet {
  width: 0.75em !important;
  height: 0.75em !important;
}

.swiper-pagination-bullet-active {
  width: 0.75em !important;
  height: 0.75em !important;
  background: #e86c4f !important;
}

.swiper-pagination-clickable {
  bottom: -5px !important;
}

.swiper-button-next {
  color: #e86c4f !important;
}

.swiper-button-prev {
  color: #e86c4f !important;
}
