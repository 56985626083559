.credits-title {
  font-family: "Lora", serif;
  color: #816638;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 0px;
}

.credits-body {
  font-family: "Roboto", sans-serif;
  margin-top: 0px;
  margin-bottom: 10px;
  margin-left: 30px;
  margin-right: 30px;
}

.credits-add {
  font-style: italic;
}

.credits-header {
  font-family: "Lora", serif;
  color: #816638;
  font-weight: 600;
  font-size: 1.25em;
  margin-top: 20px;
}

a,
a:hover {
  color: #e86c4f;
  text-decoration: none;
}
